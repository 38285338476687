<template>
    <!-- h5下载Android App -->
    <div>
        <title-nav :title="'下载APP'"/>
        <div class="download-box" id="download-box">
            <div class="download-row" v-if="appName === 'yingjing'">
                <img src="../../assets/images/logo_900_900.png" alt="" width="120px" height="120px">
                <div :class="[yingjingClicked ? 'clickedButton' : 'button']" @click="handleClick('yingjingdaohang')">应景导航Apk</div>
            </div>
            <div class="download-row" v-else-if="appName === 'career'">
                <img src="../../assets/images/logo2_600_600.png" alt="" width="120px" height="120px">
                <div :class="[zhichangClicked ? 'clickedButton' : 'button']" @click="handleClick('zhichangdaohang')">不知礼Apk</div>
            </div>
            <div class="download-row" v-else>
                <img src="../../assets/images/logo3_600_600.png" alt="" width="120px" height="120px">
                <div :class="[mediahelperClicked ? 'clickedButton' : 'button']" @click="handleClick('mediahelper')">媒辅助Apk</div>
            </div>
        </div>
        <div class="shadow" v-show="isWeixin">
            <div class="text-block">
                <div class="row">
                    <div class="number">1</div>
                    <div>点击右上角<span>···</span>按钮</div>
                </div>
                <div class="row">
                    <div class="number">2</div>
                    <div>选择在浏览器中打开</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const seo = require('../../../config/index')
import TitleNav from '../../components/nav/TitleNav.vue'

export default {
  name: 'Download',
  components: {
    TitleNav
  },
  data(){
      return {
        yingjingClicked: false,
        zhichangClicked: false,
        mediahelperClicked: false,
        isWeixin: false,
        appName:'',
      }
  },
  mounted(){
    let ua = window.navigator.userAgent.toLowerCase()
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        this.isWeixin = true
    }
    this.appName = seo.config.appId 
    if(this.$store.state.userAgent === 'PC'){
        document.getElementById("download-box").style.cssText= 'margin-top: 90px;';
    }
  },
  methods:{
        handleClick(type){
            if(type === 'yingjingdaohang'){
                this.yingjingClicked = true
                const a = document.createElement('a')
                a.href = 'https://emer-nav.cn/public/images/yingjingdaohang_v1.0.0.apk'
                a.download = 'yingjingdaohang_v1.0.0.apk'
                a.click()
            } else if(type === 'zhichangdaohang'){
                this.zhichangClicked = true
                const a = document.createElement('a')
                a.href = 'https://emer-nav.cn/public/images/zhichangdaohang_v1.0.0.apk'
                a.download = 'zhichangdaohang_v1.0.0.apk'
                a.click()
            } else if(type === 'mediahelper'){
                this.mediahelperClicked = true
                const a = document.createElement('a')
                a.href = 'https://emer-nav.cn/public/images/mediahelper_v1.0.0.apk'
                a.download = 'mediahelper_v1.0.0.apk'
                a.click()
            }
        }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
}
.text-block {
    position: absolute;
    top: 100px;
    left: 50px;
    .row {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 24px;
        font-weight: 100;
        margin-bottom: 10px;
        .number {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #ea6f7b;
            display: flex;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            color: #fff;
            margin-right: 10px;
        }
        span {
            font-weight: bold;
            font-size: 30px;
            margin: 0 5px;
        }
    }
}
.download-box {
    width: 100vw;
    height: calc(100vh - 100px);
    max-width: 625px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        border-radius: 30px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .download-row {
        min-width: 150px;
    }
    .button {
        height: 45px;
        line-height: 45px;
        border-radius: 10px;
        background: #ec745b;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #eee;
        font-size: 18px;
        font-weight: bold;
        margin: 20px auto;
        padding: 0 5px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    .clickedButton {
        height: 45px;
        line-height: 45px;
        border-radius: 10px;
        background: #969699;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #eee;
        font-size: 18px;
        font-weight: bold;
        margin: 20px auto;
        padding: 0 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}
</style>